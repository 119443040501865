// & Document: https://chakra-ui.com/docs/components/menu/theming
import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(menuAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
    // define the part you're going to style
    list: {
        py: 4,
        borderRadius: '16px',
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        zIndex: '200'
    },
    item: {
        // this will style the MenuItem and MenuItemOption components
        _hover: {
            border: "none",
            bg: 'none'
        },
        _focus: {
            border: "none",
            outline: 'none',
            bg: 'none'
        },
    },
    command: {
        // this will style the text defined by the command
        // prop in the MenuItem and MenuItemOption components
        opacity: '0.8',
        fontFamily: 'mono',
        fontSize: 'sm',
        letterSpacing: 'tighter',
        pl: '4',
    },
});

const variants = {
    selection: {
        groupTitle: {
            color: '#9CA3B0',
            fontSize: "12px",
            fontWeight: 600
        },
        item: {
            color: "#111827",
            fontSize: "14px",
            fontWeight: 400,
            py: 4,
            mb: 1,
            borderRadius: "4px",
            _hover: {
                bg: '#F5F5F5'
            },
            _disabled: {
                bg: '#f0f6ff',
                color: "#111827",
                opacity: 1,
                _hover: {
                    bg: '#f0f6ff'
                },
            }
        },
        list: {
            maxHeight: 240,
            overflow: 'auto',
            bg: "#FFFFFF",
            boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.24)",
            px: 4,
            transform: `translateX(24px)`,
            border: 'none',
            borderColor: "transparent",
        },
        button: {
            display: "flex",
            _disabled: {
                bg: 'red',
                color: "blue",
                opacity: 1,
                _hover: {
                    bg: '#f0f6ff'
                },
            }
        }
    },
    autocomplete: {
        item: {
            overflowX: "hidden",
            color: "#111827",
            fontSize: "14px",
            fontWeight: 400,
            py: 4,
            mb: 1,
            borderRadius: "4px",
            _hover: {
                bg: '#F5F5F5'
            },
            _disabled: {
                bg: '#f0f6ff',
                color: "#111827",
                opacity: 1,
                _hover: {
                    bg: '#f0f6ff'
                },
            },

        },
        list: {
            maxHeight: 240,
            overflow: 'auto',
            bg: "#FFFFFF",
            boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.24)",
            px: 4,
            transform: `translateX(24px)`,
            border: 'none',
            borderColor: "transparent",
            zIndex: 20,
        }
    },
}

const sizes = {
    m: {
        list: {
            borderRadius: '8px',
        }
    },
    s: {
        list: {
            minW: 160,
            w: 160
        }
    }
}

// export the base styles in the component theme
export const MenuDefined = defineMultiStyleConfig({ baseStyle, variants, sizes })